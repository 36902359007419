import React, {Component} from 'react'
import { withTranslation, Trans } from "react-i18next";

class TeamAvicom extends Component {

    render() {
        const {t, i18n} = this.props;

        var data = [
            {
                id: 2,
                name: "Aleksandr Viktorov",
                position: "CEO and Founder",
                imgUrl: "2"
            },
            {
                id: 1,
                name: "Katja Siilinen",
                position: "UI/UX Designer",
                imgUrl: "1"
            },
            {
                id: 3,
                name: "Juho Salminen",
                position: "Tech Lead",
                imgUrl: "3"
            },
            {
                id: 4,
                name: "Katerina Tarazevich",
                position: "Copywriter",
                imgUrl: "4"
            }
        ];

        return (
            <section className="out-tem-wrap section-padding pt-0" id="team">
                <div className="container">

                    <div className="row">
                        <div className="col-lg-8 col-md-10 col-12 offset-lg-2 offset-md-1">
                            <div className="section-title text-center">
                                <Trans i18nKey="team.text" i18n={i18n} t={t}>
                                    <span/>
                                    <h2>Meet Avicom team</h2>
                                    <p>"None of us, including me, ever do great things. But we can all do small things, with
                                        great love, and
                                        together we can do something wonderful"</p>
                                    <p className="text-right">&mdash; Mother Teresa</p>
                                </Trans>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {
                            data.map(member => {

                                return (
                                    <div className="col-md-6 col-xl-3 col-sm-12 col-12" key={member.id}>
                                        <div
                                            className={`single-team-member text-center ${member.id === 2 ? 'active' : '' }`}>
                                            <div className="member">
                                                <div className="member-img">
                                                    <img
                                                        src={require(`../assets/img/avicom-team-photo/${member.imgUrl}.jpg`)}
                                                        alt="Avicom {member.position}"/>
                                                </div>
                                                <div className="member-info">
                                                    <h4>{member.name}</h4>
                                                    <span>{member.position}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation()(TeamAvicom);
