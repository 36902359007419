import React, { Component } from 'react'
import Navbar from './Navbar';

class Header extends Component {
    render() {
        return (
            <Navbar />
        )
    }
}

export default Header
