import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import HomeAvicom from './components/HomeAvicom';
import HomeOne from './components/HomeOne';
import HomeTwo from './components/HomeTwo';
import HomeThree from './components/HomeThree';
import AOS from 'aos';
import Header from './components/Header';
import HeaderAvicom from './components/HeaderAvicom';
import Client from './components/Client';
import Cta from './components/Cta';
import Footer from './components/Footer';
import FooterAvicom from './components/FooterAvicom';
import FooterData from './components/FooterWidgets/FooterData';
import FooterDataAvicom from './components/FooterWidgets/FooterDataAvicom';

import './App.css';
import './i18n';

function App() {
  AOS.init();

  return (
    <div className="main-wrapper">
      <Router>
        <Route exact path="/" render={props => (
          <React.Fragment>
            <HeaderAvicom/>
            <HomeAvicom/>
            <FooterAvicom FooterData={FooterDataAvicom}/>
          </React.Fragment>
        )}/>
        <Route path='/HomeOne' render={props => (
          <React.Fragment>
            <Header/>
            <HomeOne/>
            <Client/>
            <Cta CtaTitle="Start building your website today!"
                 CtaDec="We make register, hosting and managing domain for yourself or others easy and affordable, because the internet needs people"
                 CtaBtnText="Get Started" ctaLink=".#"/>
            <Footer FooterData={FooterData}/>
          </React.Fragment>
        )}/>
        <Route path='/HomeTwo' render={props => (
          <React.Fragment>
            <Header/>
            <HomeTwo/>
            <Client/>
            <Cta CtaTitle="Start building your website today!"
                 CtaDec="We make register, hosting and managing domain for yourself or others easy and affordable, because the internet needs people"
                 CtaBtnText="Get Started" ctaLink=".#"/>
            <Footer FooterData={FooterData}/>
          </React.Fragment>
        )}/>
        <Route path='/HomeThree' render={props => (
          <React.Fragment>
            <Header/>
            <HomeThree/>
            <Client/>
            <Cta CtaTitle="Start building your website today!"
                 CtaDec="We make register, hosting and managing domain for yourself or others easy and affordable, because the internet needs people"
                 CtaBtnText="Get Started" ctaLink=".#"/>
            <Footer FooterData={FooterData}/>
          </React.Fragment>
        )}/>
      </Router>
    </div>
  );

}

export default App;
