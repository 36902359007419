import React, { Component } from 'react';
class Footer extends Component {

    render() { 
        var FooterData = this.props.FooterData;

        return (
            <footer className="footer-wrap footer-after-contacts footer-two">
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-12">
                                <div className="copyright-info text-center">
                                    <span>{FooterData.copyright}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
