import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({text}) => <div style={{
  'border-radius': '6px',
  'border': '2px solid white',
  'background': '#3a2975',
  'padding': '4px',
  'width': '143px'
}}>{text}</div>;

export class Gmap extends Component {

  static defaultProps = {
    center: {
      lat: 60.2240255,
      lng: 24.8417967
    },
    zoom: 11
  };


  render() {
    return (


      <div style={{height: '280px', width: '100%'}}>
        <GoogleMapReact
          bootstrapURLKeys={{key: 'AIzaSyA-M5QOtqwdasnoSQ5q9KNAgt1BMOPXqo0'}}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent
            lat={60.2240255}
            lng={24.8417967}
            text="Taavinkuja 6B, 00370 Helsinki"
          />
        </GoogleMapReact>
      </div>


    )
  }
}

export default Gmap
