import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend';

import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

const languagesWhitelist = ['en', 'fi'];

const languageDetectorOptions = {
    // order and from where user language should be detected
    order: ['path', 'navigator'],

    // keys or params to lookup language from
    lookupFromPathIndex: 0,
};

const HttpBackendOptions = {
  loadPath: 'https://avicom.fi/static/locales/{{lng}}/{{ns}}.json',

  // path to post missing resources, or a function
  // function(lng, namespace) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  addPath: '/locales/add/{{lng}}/{{ns}}',

  // your backend server supports multiloading
  // /locales/resources.json?lng=de+en&ns=ns1+ns2
  // Adapter is needed to enable MultiLoading https://github.com/i18next/i18next-multiload-backend-adapter
  // Returned JSON structure in this case is
  // {
  //  lang : {
  //   namespaceA: {},
  //   namespaceB: {},
  //   ...etc
  //  }
  // }
  allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

  // parse data after it has been fetched
  // in example use https://www.npmjs.com/package/json5
  // here it removes the letter a from the json (bad idea)
  // parse: function(data) { return data.replace(/a/g, ''); },

  //parse data before it has been sent by addPath
  // parsePayload: function(namespace, key, fallbackValue) { return { key } },

  // allow cross domain requests
  crossDomain: true,

  // allow credentials on cross domain requests
  withCredentials: false,

  // overrideMimeType sets request.overrideMimeType("application/json")
  overrideMimeType: false,

  requestOptions: { // used for fetch, can also be a function (payload) => ({ method: 'GET' })
    mode: 'no-cors',
    credentials: 'same-origin',
    cache: 'default'
  }
  
};

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next) // passes i18n down to react-i18next
    // Load translations from server
    .use(HttpApi)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        whitelist: languagesWhitelist,
        keySeparator: '.',
        nonExplicitWhitelist: true,
        returnNull: false,
        load: 'languageOnly',
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        detection: languageDetectorOptions,
        backend: HttpBackendOptions,
    });

export default i18n;