import React, {Component} from 'react'
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Navbar from 'react-bootstrap/Navbar';
import {Link as PageLink} from 'react-router-dom';
import {Link} from "react-scroll";
import $ from 'jquery';
import { withTranslation } from "react-i18next";

import {default as i18n } from '../i18n';

export class NavbarAvicom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            navExpanded: false
        }
    }

    setNavExpanded = (expanded) => {
        this.setState({navExpanded: expanded});
    }

    closeNav = () => {
        this.setState({navExpanded: false});
    }

    changeLanguage = (e) => {
        e.preventDefault();
        if (i18n.languages[0] === 'en') {
            i18n.changeLanguage('fi');
        } else {
            i18n.changeLanguage('en');
        }
        this.setState({navExpanded: false});
    }

    componentDidMount() {

        $(window).scroll(function () {
            var Width = $(document).width();

            if ($("body").scrollTop() > 100 || $("html").scrollTop() > 100) {
                $("header").addClass("sticky")
            } else {
                $("header").removeClass("sticky");
            }
        });


    }


    render() {

        const {t, i18n} = this.props;

        return (
            <header className="header-wraper transparent-menu">
                <div className="main-menu">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-10 d-flex">
                                <PageLink className="navbar-brand logo" to="/">
                                    <img src={require("../assets/img/logo.svg")} alt="Avicom"/>
                                    <h1>avicom</h1>
                                </PageLink>
                            </div>
                            <div className="col-md-8 col-2 text-lg-right">
                                {/*<Navbar expand="lg" className="menu-1">
                  <Navbar.Toggle aria-controls="basic-navbar-nav">
                    <span className="fal fa-bars"/>
                  </Navbar.Toggle>
                  <Navbar.Collapse id="basic-navbar-nav" className="menu-items">
                    <Nav className="mr-auto">
                      <NavDropdown title="Pages" id="basic-nav-dropdown">
                        <Nav.Item>
                          <PageLink to="/">Home Avicom</PageLink>
                        </Nav.Item>
                        <Nav.Item>
                          <PageLink to="/HomeOne">Home 1</PageLink>
                        </Nav.Item>
                        <Nav.Item>
                          <PageLink to="/HomeTwo">Home 2</PageLink>
                        </Nav.Item>
                        <Nav.Item>
                          <PageLink to="/HomeThree">Home 3</PageLink>
                        </Nav.Item>
                      </NavDropdown>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="feature" spy={true} smooth={true}
                              offset={-70}
                              duration={500}> Features
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="price" spy={true} smooth={true} offset={-70}
                              duration={500}> Pricing
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="service" spy={true} smooth={true}
                              offset={-70}
                              duration={500}>Services</Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="faq" spy={true} smooth={true} offset={-70}
                              duration={500}>
                          FAQ
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="testimonial" spy={true} smooth={true}
                              offset={-70}
                              duration={500}> Testimonial
                        </Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Link className="nav-link" activeClass="active" to="contact" spy={true} smooth={true}
                              offset={-70}
                              duration={500}> Contact
                        </Link>
                      </Nav.Item>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>*/}
                                <Navbar expand="lg" className="menu-1" onToggle={this.setNavExpanded}
                                        expanded={this.state.navExpanded}>
                                    <Navbar.Toggle aria-controls="basic-navbar-nav">
                                        <span className="fal fa-bars"/>
                                    </Navbar.Toggle>
                                    <Navbar.Collapse id="basic-navbar-nav"
                                                     className="menu-items menu-items--balloon toggle-with-bg">
                                        <Nav.Item>
                                            <a onClick={this.changeLanguage} href={'/'} className="nav-link nav-link--language">
                                                {i18n.languages[0] === 'en' ? (
                                                    <img src={require(`../assets/img/flags/suomi.svg`)} alt={'Suomi'}/>
                                                ) : (
                                                    <img src={require(`../assets/img/flags/uk.svg`)} alt={'English'}/>
                                              )}
                                            </a>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link onClick={this.closeNav} className="nav-link" activeClass="active" to="service" spy={true}
                                                  smooth={true}
                                                  offset={-30}
                                                  duration={500}> {t('menu.item.advantages', 'Advantages')}
                                            </Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link onClick={this.closeNav} className="nav-link" activeClass="active" to="our-products" spy={true}
                                                  smooth={true}
                                                  offset={-30}
                                                  duration={500}> {t('menu.item.about', 'About us')}
                                            </Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link onClick={this.closeNav} className="nav-link" activeClass="active" to="team" spy={true}
                                                  smooth={true}
                                                  offset={-110}
                                                  duration={500}> {t('menu.item.team', 'Team')}
                                            </Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Link onClick={this.closeNav} className="nav-link" activeClass="active" to="contact" spy={true}
                                                  smooth={true}
                                                  offset={-30}
                                                  duration={500}> {t('menu.item.contacts', 'Contacts')}
                                            </Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <a href="tel:+358401563900" className="sign theme-btn nav-link p-3"><i
                                                className="fal fa-headset"/> &nbsp; +358401563900</a>
                                        </Nav.Item>
                                        {/*<Nav.Item>
                      <a href="mailto:contact@avicom.fi" className="nav-link"><i className="fal fa-envelope-open-text" /> &nbsp; contact@avicom.fi</a>
                    </Nav.Item>*/}
                                    </Navbar.Collapse>
                                </Navbar>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}

export default withTranslation()(NavbarAvicom);
