const ServiceData = {
    serviceBoxItem: [
        {
            id:1,
            title:"Easy to Setup",
            text:"Lorem Ipsum is simply dummy and typesetting industry.",
            imgUrl:"setup.png"
        },
        {
            id:2,
            title:"Fast Performance",
            text:"Lorem Ipsum is simply dummy and typesetting industry.",
            imgUrl:"fast.png"
        },
        {
            id:3,
            title:"Privacy Protected",
            text:"Lorem Ipsum is simply dummy and typesetting industry.",
            imgUrl:"privacy.png"
        },
        {
            id:4,
            title:"Monthly Backup",
            text:"Lorem Ipsum is simply dummy and typesetting industry.",
            imgUrl:"backup.png"
        },
        {
            id:5,
            title:"Free SSL Certificate",
            text:"Lorem Ipsum is simply dummy and typesetting industry.",
            imgUrl:"ssl.png"
        },
        {
            id:6,
            title:"Unlimited Database",
            text:"Lorem Ipsum is simply dummy and typesetting industry.",
            imgUrl:"database.png"
        },
        {
            id:7,
            title:"Boost Option",
            text:"Lorem Ipsum is simply dummy and typesetting industry.",
            imgUrl:"boost.png"
        },
        {
            id:8,
            title:"Multiple Network",
            text:"Lorem Ipsum is simply dummy and typesetting industry.",
            imgUrl:"network.png"
        }
    ],

}


export default ServiceData
