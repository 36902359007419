import React, {Component} from 'react'
import {withTranslation} from "react-i18next";
import SectionTitle from '../SectionTitle'
import {Gmap} from '../Gmap';
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import CheckButton from 'react-validation/build/button';
import {isEmail, isEmpty, isMobilePhone} from 'validator';
import axios from 'axios';

class ContactAvicom extends Component {

    constructor(props) {
        super(props);
        let {filesToDownload} = props;
        let filesRefs = [];
        (filesToDownload || []).map(() => {
            filesRefs.push(React.createRef());
        });
        this.state = {
            name: '',
            phone: '',
            email: '',
            content: '',
            filesRefs: filesRefs,
            isSending: false,
            success: false,
            errorMessage: ''
        }
    }

    required = (value) => {
        const {t, i18n} = this.props;
        if (isEmpty(value)) {
            return <small className="form-text text-danger">{t('contacts.form.errors.required', 'This field is required')}</small>;
        }
    };

    email = (value) => {
        const {t, i18n} = this.props;
        if (value && !isEmail(value)) {
            return <small className="form-text text-danger">{t('contacts.form.errors.emailInvalid', 'Invalid email format')}</small>;
        }
    };

    phone = (value) => {
        const {t, i18n} = this.props;
        if (!isMobilePhone(value)) {
            return <small className="form-text text-danger">{t('contacts.form.errors.phoneInvalid', 'Invalid Phone number format')}</small>;
        }
    };

    onSubmit = (e) => {
        const {t, i18n} = this.props;
        e.preventDefault();
        this.form.validateAll();

        if (this.checkBtn.context._errors.length === 0) {
            let {formId, subject} = this.props;
            const data = {
                'data': {
                    'name': this.state.name,
                    'email': this.state.email,
                    'phone': this.state.phone,
                    'content': this.state.content,
                },
                'subject': subject,
                'form_id': formId,
                'utm_data': {},
                'url': window.location.href
            };

            this.setState({
                isSending: true,
                errorMessage: '',
                success: false
            });

            axios.post('/contact/api/submit/', data)
                .then(({data: response}) => {
                    this.setState({
                        isSending: false,
                        errorMessage: '',
                        success: true
                    });
                    this.state.filesRefs.map((ref) => {
                        ref.current.click();
                    });
                }).catch(({error: response}) => {
                this.setState({
                    isSending: false,
                    errorMessage: t('contacts.form.errors.common', 'An error occurred, please, try to resubmit'),
                    success: false
                });
            });
        }
    };

    render() {
        const {t, i18n} = this.props;
        return (
            <section className="contact-us-wrap section-padding contact-v3" id="contact">
                <div className="container">

                    <SectionTitle Title={t('contacts.form.title', 'Drop us a message')}
                                  Titlep={t('contacts.form.subtitle', 'Please fill in the form below and we will respond to all queries as soon as possible')}/>

                    <div className="row">
                        <div className="col-md-12 col-lg-6 col-12">
                            <div className="form-wrap">
                                <Form className="row" onSubmit={e => this.onSubmit(e)} ref={c => {
                                    this.form = c
                                }}>
                                    <div className="col-12">
                                        <div className="single-input">
                                            <Input name="name" onChange={(e) => {
                                                this.setState({
                                                    name: e.target.value,
                                                    success: false
                                                })
                                            }} value={this.state.name} type="text" placeholder={t('contacts.form.name.placeholder', 'Your name *')}
                                                   className="form-control" validations={[this.required]}/>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="single-input">
                                            <Input name="phone" onChange={(e) => {
                                                this.setState({
                                                    phone: e.target.value,
                                                    success: false
                                                })
                                            }} value={this.state.phone} type="text" placeholder={t('contacts.form.name.placeholder', 'Phone number *')}
                                                   className="form-control" validations={[this.required, this.phone]}/>
                                        </div>
                                        <div className="single-input">
                                            <div className="form-group">
                                                <Input name="email" onChange={(e) => {
                                                    this.setState({
                                                        email: e.target.value,
                                                        success: false
                                                    })
                                                }} value={this.state.email} type="email" placeholder={t('contacts.form.email.placeholder', 'Your e-mail')}
                                                       className="form-control"
                                                       validations={[this.email]}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="single-input">
                                            <Textarea onChange={(e) => {
                                                this.setState({
                                                    content: e.target.value,
                                                    success: false
                                                })
                                            }} value={this.state.content} className="form-control"
                                                      placeholder={t('contacts.form.text.placeholder', 'How can we help you?')} rows="3"
                                                      validations={[]}/>
                                        </div>
                                    </div>
                                    <CheckButton style={{display: 'none'}} ref={c => {
                                        this.checkBtn = c
                                    }}/>
                                    <div className="col-12 col-lg-12 mt-40">
                                        {this.state.success && (
                                            <div className={`text-success mb-4`}>
                                                {t('contacts.form.success.message', 'Thank you, we\'ll contact you soon!')}
                                            </div>
                                        )}
                                        {this.state.errorMessage ? (
                                            <>
                                                <div className={`text-danger mb-4`}>
                                                    {this.state.errorMessage}
                                                </div>
                                                <button type="submit"
                                                        className="theme-btn f3">{t('contacts.form.label.resubmit', 'Resubmit')}
                                                </button>
                                            </>
                                        ) : (
                                            <button type="submit"
                                                    className="theme-btn f3">{t('contacts.form.label.submit', 'Submit')}</button>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-5 offset-lg-1 col-12 get-contact text-white">
                            <h4>{t('contacts.form.subtitle2', 'Get Instant Reply')}</h4>
                            <div className="row">
                                {/* /.single-contact-info */}
                                <div className="single-contact-info col-12 col-md-6 col-lg-12 col-xl-6">
                                    <div className="contact-icon">
                                        <i className="fal fa-headset"/>
                                    </div>
                                    <div className="contact-info">
                                        <p><a href="tel:+358401563900">+358401563900</a></p>
                                    </div>
                                </div>
                                <div className="single-contact-info col-12 col-md-6 col-lg-12 col-xl-6">
                                    <div className="contact-icon">
                                        <i className="fab fa-whatsapp"/>
                                    </div>
                                    <div className="contact-info">
                                        <p><a href="https://wa.me/358401563900" target="_blank">WhatsApp</a></p>
                                    </div>
                                </div>
                                <div className="single-contact-info col-12 col-md-6 col-lg-12 col-xl-6">
                                    <div className="contact-icon">
                                        <i className="fab fa-telegram-plane"/>
                                    </div>
                                    <div className="contact-info">
                                        <p><a href="https://t.me/Finnzleif" target="_blank">Telegram</a></p>
                                    </div>
                                </div>
                                <div className="single-contact-info col-12 col-md-6 col-lg-12 col-xl-6">
                                    <div className="contact-icon">
                                        <i className="fab fa-viber"/>
                                    </div>
                                    <div className="contact-info">
                                        <p><a href="viber://chat?number=358401563900" target="_blank">Viber</a></p>
                                    </div>
                                </div>
                                {/* /.single-contact-info */}
                                <div className="single-contact-info col-12 col-md-6 col-lg-12 col-xl-6">
                                    <div className="contact-icon">
                                        <i className="far fa-envelope-open-text"/>
                                    </div>
                                    <div className="contact-info">
                                        <p><a href="mailto:contact@avicom.fi">contact@avicom.fi</a></p>
                                    </div>
                                </div>
                            </div>
                            <div className="google-map mt-20">
                                <div id="map">
                                    <Gmap/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default withTranslation()(ContactAvicom);
