import React, {Component} from 'react'
import { Trans, withTranslation } from 'react-i18next';
import SectionTitle from './SectionTitle'


class ContentBlockAvicom extends Component {
  render() {

    const {t, i18n} = this.props;

    return (
      <section className="content-block-wrap section-padding pb-130" id="our-products">
        <div className="container">

          <SectionTitle Title={t('contentBlock.section.title', "What we can offer")}
                        Titlep={t('contentBlock.section.text', "Our company knows how to digitalize your business efficiently using modern technology.")}/>

          <div className="row">
            <div className="col-xl-5 col-lg-6  col-sm-12 col-12">
              <div className="content-block" data-aos="fade-right" data-aos-duration={1200}>
                <Trans i18nKey="contentBlock.first.text" i18n={i18n} t={t}>
                  <span className="top-title">Avicom</span>
                  <h2>Hauska tutustua!*</h2>
                  <p>Avicom is a Finnish technology company that creates digital solutions. The full potential of
                    technology is underutilized in everyday life. We are making the most of the digital era to offer new
                    ways of managing standardized tasks.</p>
                  <ul>
                    <li>5+ years of experience in IT</li>
                    <li>24/7 Online Customer Support</li>
                    <li>High-level specs who are passionate about what they do</li>
                    <li className="li-asterisk">Nice to meet you!</li>
                  </ul>
                </Trans>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 offset-xl-1 col-sm-12 col-12">
              <div className="content-featured-img" data-aos="fade-left" data-aos-duration={1200}>
                <img src={require("../assets/img/content/avicom-team.png")} alt={t('contentBlock.first.imgAlt', 'Avicom')}/>
              </div>
            </div>
          </div>
          <div className="row flex-row-reverse">
            <div className="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 col-12">
              <div className="content-block pt-60" data-aos="fade-left" data-aos-duration={1200}>
                <Trans i18nKey="contentBlock.second.text" i18n={i18n} t={t}>
                  <span className="top-title">ESL</span>
                  <h2>Electronic shelf labels</h2>
                  <p>Our electronic shelf labels provide a digital solution for retail, management, exhibition and manufacturing. Simply display and quickly update product information, promotions and discounts to your audience.</p>
                  <p>To execute label management, we include an easy-to-use custom mobile app. Digital labels are more cost-efficient than standard paper labels and offer an eco-friendly way to modernize your business.</p>
                </Trans>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
              <div className="content-featured-img pt-60" data-aos="fade-right" data-aos-duration={1200} >
                <img src={require("../assets/img/content/esl.png")} alt={t('contentBlock.second.imgAlt', 'Electronic shelf labels')}/>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-5 col-lg-6 col-sm-12 col-12">
              <div className="content-block pt-60" data-aos="fade-up" data-aos-duration={1200}>
                <Trans i18nKey="contentBlock.third.text" i18n={i18n} t={t}>
                  <span className="top-title">ChatBot</span>
                  <h2>Smart chat center for high-quality customer and cost optimization support</h2>
                  <p>For small businesses, we provide a cloud platform for creating smart bots in instant messengers. For medium and large businesses we offer a team of experts implementing solutions based on artificial intelligence.
  Your communication with clients in all instant messengers will be easy and quick. Like a chat with your friends.</p>
                  <ul>
                    <li>Processing thousands of applications 24/7/365</li>
                    <li>Replacing first-line support operators</li>
                    <li>Systematization of the feedback collection and accounting</li>
                    <li>Unified level of service in all channels</li>
                  </ul>
                </Trans>
              </div>
            </div>
            <div className="col-lg-6 col-xl-6  offset-xl-1 col-sm-12 col-12">
              <div className="content-featured-img pt-60" data-aos="fade-up" data-aos-duration={1200}>
                <img src={require("../assets/img/content/chatbot.png")} alt={t('contentBlock.third.imgAlt', 'Chatbots')}/>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default withTranslation()(ContentBlockAvicom);
