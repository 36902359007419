import React, { Component, Suspense } from 'react'
import NavbarAvicom from './NavbarAvicom';

class HeaderAvicom extends Component {
    render() {
        return (
          <Suspense fallback={''}>
            <NavbarAvicom />
          </Suspense>
        )
    }
}

export default HeaderAvicom
