const TestimonialData = {
    reviewItem: [
        {
            id:1,
            imgUrl:"1",
            cName:"Mary W. Pollack",
            cPosition:"CEO ",
            companyName:"Envato",
            reviewText:"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia"
        },
        {
            id:2,
            imgUrl:"2",
            cName:"Azizul Haque",
            cPosition:"CEO",
            companyName:"KeenDevs",
            reviewText:"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia"
        },
        {
            id:3,
            imgUrl:"3",
            cName:"Saddam H.",
            cPosition:"UI Designer",
            companyName:"WPPool",
            reviewText:"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia"
        },
        {
            id:4,
            imgUrl:"4",
            cName:"Salman Ahmed",
            cPosition:"Developer",
            companyName:"WPPool",
            reviewText:"Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia"
        }
    ],

}


export default TestimonialData
