import React, {Component, Suspense} from 'react';
import HeroAvicom from './Hero/HeroAvicom';
import FeaturesAvicom from './Features/FeaturesAvicom';
import ServiceAvicom from './Services/ServiceAvicom';
import ContentBlockAvicom from './ContentBlockAvicom';
import ContactAvicom from './Contact/ContactAvicom';
import TeamAvicom from './TeamAvicom';

class HomeAvicom extends Component {

    render() {
        return (
            <React.Fragment>
              <Suspense fallback={''}>
                <HeroAvicom/>
                <FeaturesAvicom/>
                <ServiceAvicom/>
                <ContentBlockAvicom/>
                <TeamAvicom/>
                <ContactAvicom/>
              </Suspense>
            </React.Fragment>
        )
    }
}

export default HomeAvicom
