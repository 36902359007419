import React, {Component} from 'react';
import { withTranslation } from 'react-i18next';
import Slider from 'react-slick';


class HeroAvicom extends Component {
  render() {
    const {t, i18n} = this.props;
    const settings = {
      slidesToShow: 1,
      dots: true,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 6000
    };
    return (
      <section className="hero-wrapper">
        <div className="single-hero-slide">
          <div className="hero-shape-wrap">
            <img src={require("../../assets/img/shape/9.png")} className="shape shape1" alt=""/>
            <img src={require("../../assets/img/shape/10.png")} className="shape shape2" alt=""/>
            <img src={require("../../assets/img/shape/11.png")} className="shape shape3" alt=""/>
            <img src={require("../../assets/img/shape/12.png")} className="shape shape4" alt=""/>
            <img src={require("../../assets/img/shape/13.png")} className="shape shape5" alt=""/>
            <img src={require("../../assets/img/shape/14.png")} className="shape shape6" alt=""/>
            <img src={require("../../assets/img/shape/15.png")} className="shape shape7" alt=""/>
            <img src={require("../../assets/img/shape/16.png")} className="shape shape8" alt=""/>
            <img src={require("../../assets/img/shape/17.png")} className="shape shape9" alt=""/>
            <img src={require("../../assets/img/shape/17.png")} className="shape shape10" alt=""/>
            <img src={require("../../assets/img/shape/17.png")} className="shape shape11" alt=""/>
          </div>
          <div className="slide-bg-wrapper">
            <div className="slide-bg">
              <img src={require("../../assets/img/avicom-header.svg")} />
            </div>
          </div>
          <section className="hero-slider-wrap-transparent">
            <Slider className="hero-slider-active" {...settings}>
              <div className="single-slide">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 text-center">
                      <div className="hero-content">
                        <img src={require("../../assets/img/main-icons/it.svg")} />
                        <h1 className="fs-lg mb-20">{t('mainSlider.first.title', 'IT')}</h1>
                        <p>{t('mainSlider.first.text', 'Avicom offers a full range of IT services, including project consulting, planning andexecution,as well as full-cycle software, mobile app and web development.')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-slide">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 text-center">
                      <div className="hero-content">
                        <img src={require("../../assets/img/main-icons/digitalization.svg")} />
                        <h1 className="fs-lg mb-20">{t('mainSlider.second.title', 'Digitalization')}</h1>
                        <p>{t('mainSlider.second.text', 'Digitalization of your business model is a complex process and should be left to the experts. We can help transform your business mechanism.')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-slide">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-8 offset-xl-2 col-lg-10 offset-lg-1 col-12 text-center">
                      <div className="hero-content">
                        <img src={require("../../assets/img/main-icons/digital-solutions.svg")} />
                        <h1 className="fs-lg mb-20">{t('mainSlider.third.title', 'Digital solutions')}</h1>
                        <p>{t('mainSlider.third.text', 'We can help integrate digital solutions into your daily operations to facilitate business growth.')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </section>
        </div>
      </section>
    )
  }
}

export default withTranslation()(HeroAvicom);